<template>
  <div class="auth-wrapper auth-v2">
    <v-btn
      elevation="0"
      color="transparent"
      style="position: absolute; z-index:1;"
      class="mt-2"
    >
      <AppBarI18n></AppBarI18n>
    </v-btn>
    <a
      v-if="$vuetify.breakpoint.mdAndDown"
      href="https://www.anita-lee.com"
      target="_blank"
    >
      <div
        dir="ltr"
        class="d-flex pt-2"
        style="position: absolute;"
        :style="$vuetify.rtl ? 'left:15px' : 'right: 15px'"
      >
        <v-img
          :src="appLogo"
          alt="logo"
          contain
          eager
          max-height="35px"
          max-width="35px"
        ></v-img>

        <v-img
          :src="appName"
          alt="name"
          contain
          eager
          max-height="40px"
          max-width="120px"
          class="mt-2"
        ></v-img>
      </div>
    </a>

    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col
          lg="8"

          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="462"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
            />

            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/not-authorized.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>
        <v-col
          lg="4"
          class="d-flex align-center justify-center auth-bg pb-0"
        >
          <!-- brand logo -->
          <a
            v-if="!$vuetify.breakpoint.mdAndDown"
            href="https://www.anita-lee.com"
            target="_blank"
          >
            <div
              dir="ltr"
              class="brand-logo d-flex align-center justify-center"
            >
              <v-img
                :src="appLogo"
                alt="logo"
                contain
                eager
                max-height="35px"
                max-width="35px"
              ></v-img>

              <v-img
                :src="appName"
                alt="name"
                contain
                eager
                max-height="40px"
                max-width="120px"
              ></v-img>
            </div>
          </a>

          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text class="text-center mb-1">
                  <p
                    class="font-weight-semibold mb-0"
                    :class="$vuetify.breakpoint.mdAndDown? 'text-xl': 'text-2xl'"
                  >
                    {{ $t('Login') }}
                  </p>
                  <p class="mb-0">
                    {{ $t('HappyToBePartOfYourEvent') }}
                  </p>
                </v-card-text>

                <v-card-text class="text-center">

                  <!-- social links -->
                  <v-card-actions class="d-flex justify-center">
                    <google-auth></google-auth>
                  </v-card-actions>
                  <!-- divider -->
                  <v-card-text class="d-flex align-center mt-2">
                    <v-divider></v-divider>
                    <span class="mx-5">
                      {{ $t('Or') }}
                    </span>
                    <v-divider></v-divider>
                  </v-card-text>

                  <span
                    v-if="incorrectIdentification"
                    class="red--text"
                  >
                    <div class="d-flex">
                      <span>*</span>
                      <span>
                        {{ $t('incorrectIdentificationDetails') }}
                      </span>
                    </div>
                  </span>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="loginForm"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-text-field
                      v-model="email"
                      outlined
                      :label="$t('Email')"
                      :placeholder="$t('Email')"
                      :error-messages="errorMessages.email"
                      :rules="[validators.required, validators.emailValidator]"
                      hide-details="auto"
                      class="mb-6"
                      dense
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      :label="$t('Password')"
                      :error-messages="errorMessages.password"
                      :placeholder="$t('Password')"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-2"
                      dense
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <div class="d-flex align-center justify-end flex-wrap">
                      <!-- forget link -->
                      <router-link
                        :to="{name:'forgot-password'}"
                      >
                        {{ $t('ForgotPassword') }}
                      </router-link>
                    </div>

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                    >
                      {{ $t('Login') }}
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <v-card-text
                  class="d-flex align-center justify-center flex-wrap mt-2 font-weight-semibold"
                  style="font-size: 1rem;"
                >
                  <span class="mb-0 me-2">
                    {{ $t('FirstEvent') }}
                    <!-- New on our platform? -->
                  </span>
                  <router-link
                    :to="{name:'auth-register'}"
                  >
                    {{ $t('Register') }}
                    <!-- Create an account -->
                  </router-link>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline, import/no-cycle
import { authenticate } from '@/views/globalHelpers'
// eslint-disable-next-line import/no-cycle
import axios from '@axios'
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import { emailValidator, required } from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { ref } from '@vue/composition-api'
import Vue from 'vue'

export default {
  components: { AppBarI18n, GoogleAuth: () => import('./GoogleAuth.vue') },
  setup() {
    // Template Ref
    const loginForm = ref(null)

    const isPasswordVisible = ref(false)

    const email = ref()
    const password = ref()
    const errorMessages = ref([])
    const incorrectIdentification = ref(false)

    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      authenticate(
        axios.post('auth/login', {
          username: email.value,
          password: password.value,
        }),

        err => {
          if (err) {
            errorMessages.value = err
            if (err === 'wrong_credentials') {
              incorrectIdentification.value = true
            }
          } else {
            // Tracking successful login
            Vue.prototype.$gtag.event('login', {
              event_category: 'Login',
              event_label: 'Success',
            })
          }
        },
      )
    }

    return {
      handleFormSubmit,
      isPasswordVisible,
      email,
      password,
      errorMessages,
      incorrectIdentification,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
